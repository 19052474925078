import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	onAuthStateChanged, signOut
} from "firebase/auth";
import { auth, provider } from "../utils/firebase";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";

export const signUp = (email, pass) => {
	return new Promise((resolve, reject) => {

		createUserWithEmailAndPassword(auth, email, pass)
			.then((userCredential) => {
				// Signed up 
				const user = userCredential.user;
				// console.warn(user)
				resolve(user);
				// ...
			})
			.catch((error) => {
				// const errorCode = error.code;
				const errorMessage = error.message;
				reject(errorMessage);
				// ..
			});

	});

}


export const signIn = (email, pass) => {
	return new Promise((resolve, reject) => {
		signInWithEmailAndPassword(auth, email, pass)
			.then((userCredential) => {
				const user = userCredential.user;
				resolve(user);
			})
			.catch((error) => {
				debugger;
				reject(error.message);
			});
	});

}

export const onAuthChange = () => {
	return new Promise((resolve, reject) => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				// User is signed in, see docs for a list of available properties
				// https://firebase.google.com/docs/reference/js/auth.user
				const data = {
					email: user.email,
					uid: user.uid,
					displayName: user.displayName,
					photoURL: user.photoURL
				};
				resolve(data)
				// ...
			} else {
				// User is signed out
				// ...
			}
		});
	})
}

export const logOff = () => {
	return new Promise((resolve, reject) => {
		signOut(auth).then(() => {
			resolve("signOff");
			// Sign-out successful.
		}).catch((error) => {
			// An error happened.
		});
	});
}





// Google Signin 
export const GoogleLogin = () => {
	return new Promise((resolve, reject) => {
		signInWithPopup(auth, provider)
			.then((result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				const credential = GoogleAuthProvider.credentialFromResult(result);
				const token = credential.accessToken;
				// The signed-in user info.
				const user = result.user;
				// console.warn("Google Log", token, user)
				resolve(token, user);
				// IdP data available using getAdditionalUserInfo(result)
				// ...
			}).catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.customData.email;
				// The AuthCredential type that was used.
				const credential = GoogleAuthProvider.credentialFromError(error);
				console.warn(errorCode, errorMessage, email, credential)
				// ...
			});
	})
}


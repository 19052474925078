import { createSlice } from "@reduxjs/toolkit";

const imgData = createSlice({
	name: "Image",
	initialState: {},
	reducers: {
		carImage: (state, action) => {
			return action.payload;
		},
		clearImage: (state, action) => {
			return null;
		}
	}
});

export const { carImage, clearImage } = imgData.actions;
export default imgData.reducer;
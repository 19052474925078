import { useDispatch } from "react-redux"
import { logOff } from "../../action/auth"
import "./style.css"
import { logedOut } from "../../store/slice/userSlice"
import Menu from "./Menu"
import { useState } from "react"
import Logo from "./Logo"
import cog from "../../img/cog.png"

const Header = () => {
	const dispatch = useDispatch();
	const [menuOpen, setMenuOpen] = useState(false);
	const openMenu = () => {
		setMenuOpen(!menuOpen)
	}
	const SignOutCTA = async () => {
		const res = await logOff();
		// console.warn("SignOutCTA", res)
		if (res === "signOff") {
			dispatch(logedOut(null))
		}
	}
	return (
		<>
			<div className="header">
				<Logo />
				<div className="header_icon" onClick={openMenu}>
					<img src={cog} alt="" />
				</div>
				<div className="header_menu">
				</div>
			</div>
			{menuOpen && <Menu openMenu={openMenu} SignOutCTA={SignOutCTA} />}
		</>
	);
}

export default Header;
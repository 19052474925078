
import { useSelector } from "react-redux";
import "./style.css"
const Details = (props) => {

	const ownerData = useSelector((store) => store.carOwner)
	const imageData = useSelector((store) => store.image)
	const { Address = null, Customer_First_Name = null, Customer_Last_Name = null,
		rc_pucc_no = null, rc_pucc_expiry_date = null, Previous_insurance_Company = null,
		Previous_insurance_expiry_date = null, rc_registration_number = null,
		kgi_make_desc = null, kgi_model_desc = null, kgi_fueltype = null, kgi_variant_desc = null
	} = ownerData || {};
	const imgPath = imageData?.imgSrcData?.photos[0]?.src?.medium;
	// console.warn(ownerData)
	props.setShowLoader(false)
	props.setNewSearch(true)
	return (
		<>
			{<div className="Details">
				<div className="carData">
					<img src={imgPath} alt="" />
					<div className="carData_data">
						<div className="car_no">{rc_registration_number}</div>
						<div className="type">
							<div className="maker">{kgi_make_desc}</div>
							<div className="Model">{kgi_model_desc} {kgi_variant_desc}</div>
							<div className="variant">{kgi_fueltype}</div>
						</div>

					</div>
				</div>
				<div className="ownerData datas">
					<div className="name">{Customer_First_Name}</div>
					<div className="other">{Customer_Last_Name}</div>
				</div>
				<div className="adress datas">
					<div className="perma">{Address}</div>
				</div>
				<div className="polu datas">
					<div className="polu_no">{rc_pucc_no}</div>
					<div className="polu_exp">{rc_pucc_expiry_date}</div>
				</div>
				<div className="ins datas">
					<div className="ins_no">{Previous_insurance_Company}</div>
					<div className="ins_exp">{Previous_insurance_expiry_date}</div>
				</div>
			</div>}
		</>
	);
}

export default Details;
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Webcam from "react-webcam";
import Tesseract from 'tesseract.js';
import { carImage } from "../../store/slice/imgData";

const PicClick = (props) => {
	const { setCarVal, setimgSearch } = props;
	const dispatch = useDispatch();
	const webcamRef = useRef(null);
	const [imgSrc, setImgSrc] = useState(null);
	const [recognizedText, setRecognizedText] = useState('');

	const capture = React.useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot();
		setImgSrc(imageSrc);
		// console.warn(imageSrc);
		dispatch(carImage({ imageSrc }))
	}, [webcamRef, setImgSrc, dispatch]);

	useEffect(() => {
		const recognizeText = async () => {
			if (imgSrc) {
				const result = await Tesseract.recognize(imgSrc);
				const textData = (result.data.text).replace(/\W|_/g, '')
				setRecognizedText(textData);
				setCarVal(textData);
				setimgSearch(false)
			}
		};
		recognizeText();
	}, [imgSrc, setCarVal, setimgSearch]);

	const videoConstraints = {
		facingMode: "environment"
	};

	return (
		<>
			<Webcam
				audio={false}
				ref={webcamRef}
				screenshotFormat="image/jpeg"
				videoConstraints={{
					...videoConstraints,
				}}
			/>
			<button onClick={capture}>Capture photo</button>
			{recognizedText}
		</>
	);
};



export default PicClick;
import { createSlice } from "@reduxjs/toolkit";

const carOwn = createSlice({
	name: "carOwn",
	initialState: {},
	reducers: {
		ownerInfo: (state, action) => {
			return action.payload;
		},
		// carImg: (state, action) => {
		// 	return action.payload;
		// },
		cleanOwnerInfo: (state, action) => {
			return null;
		}
	}
});

export const { ownerInfo, cleanOwnerInfo } = carOwn.actions;

export default carOwn.reducer;
import "./style.css"
import searchLogo from "../../../img/search.gif"
import capture from "../../../img/capture.png"
import { carCheck } from "../../../action/carDetails";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ownerInfo } from "../../../store/slice/carOwn";
import PicClick from "../../Camera";

const Search = (props) => {
	const { setShowLoader } = props;
	const [carVal, setCarVal] = useState("");
	const [dataCar, setDatacar] = useState(null)
	const [imgSearch, setimgSearch] = useState(false)
	const dispatch = useDispatch();
	const car = useRef();
	useEffect(() => {
		if (dataCar != null) { setShowLoader(false) };
	}, [dataCar]);

	const carAPI = async () => {
		props.setShowLoader(true);
		setCarVal(car.current.value)
		const Data = await carCheck((car.current.value).replace(/\W|_/g, ''));
		// const Data = "swwadda";
		const CarData = JSON.parse(Data);
		setDatacar(CarData);
		dispatch(ownerInfo(CarData))
	}
	const inpuD = () => {
		setCarVal((car.current.value).replace(/\W|_/g, ''))
	}
	const chnageMethod = () => {
		setimgSearch(!imgSearch)
	}
	useSelector((store) => store.carOwn)
	// console.warn(imgtoText)
	const closeM = () => {
		setimgSearch(false);
	}

	return (
		<div className="search">
			<div className="searchBox">
				<img src={searchLogo} alt="" />
				<div className="input">
					<div className="srimg">
						<input type="text" ref={car} value={carVal} onChange={inpuD} placeholder="Enter Car Number" />
						<img src={capture} alt="" onClick={chnageMethod} />
					</div>


					<div className="getDetail" onClick={carAPI}>Get Details</div>
				</div>
			</div>
			{imgSearch && <div className="CamPic">
				<div onClick={closeM}>Close</div>
				<PicClick setimgSearch={setimgSearch} setCarVal={setCarVal} />
			</div>}
		</div>
	);
}
export default Search;



// const APIk = "bQ9z5Ki2f8CVEXKSEe0BwJQpxIfHkits0nkd2zU0hghOSfshcXhUzjDY";


// export const Image = async (val) => {
// 	const res = await fetch(`https://api.pexels.com/v1/search?query=${val}&per_page=1`, {
// 		headers: { Authorization: APIk }
// 	});
// 	const dataJson = await res.json();
// 	return dataJson;
// }

import { createClient } from 'pexels';

const client = createClient('bQ9z5Ki2f8CVEXKSEe0BwJQpxIfHkits0nkd2zU0hghOSfshcXhUzjDY');
export const Image = async (query) => {
	const dataImg = await client.photos.search({ query, per_page: 1 }).then(photos => {
		return photos;
	});
	return dataImg;
}

import { createBrowserRouter } from "react-router-dom";
import Login from "./components/Login";
import Vahaan from "./components/Vahaan";
import { RouterProvider } from "react-router-dom";
import { useEffect, useState } from "react";
import { onAuthChange } from "./action/auth";
import { useDispatch, useSelector } from "react-redux";
import { logedIn, logedOut } from "./store/slice/userSlice";
import Header from "./components/Header";
import Loading from "./components/Loading";


function App() {
  const [showLoader, setShowLoader] = useState(true);
  const userData = useSelector(store => store.user);
  const commonProps = {
    setShowLoader
  }
  const appRoutes = createBrowserRouter([
    {
      path: "/",
      element: userData?.email ? <Vahaan {...commonProps} /> : <Login {...commonProps} />
    },
    {
      path: "/vahaan",
      element: <Vahaan {...commonProps} />
    }
  ]);
  const dispatch = useDispatch()

  useEffect(() => {
    async function userUpdate() {
      const data = await onAuthChange();
      // console.warn(data)
      data ? dispatch(logedIn(data)) : dispatch(logedOut(null));
    }
    userUpdate();
  }, [dispatch]);



  return (
    <div className="App">
      {userData?.email && <Header />}
      {showLoader && <Loading />}
      <RouterProvider router={appRoutes} />
    </div>
  );
}

export default App;

import "./style.css"
import logout from "../../../img/logout.png"
import closeBtn from "../../../img/close.gif"
import { useSelector } from "react-redux"

const Menu = (props) => {
	const closeMenu = () => {
		props.openMenu()
	}
	const logoff = () => {
		props.SignOutCTA()
	}
	const userData = useSelector((store) => store.user);
	return (
		<div className="menu">
			<div className="close_menu" onClick={closeMenu}>
				<img src={closeBtn} alt="" />
			</div>
			<div className="menu_item">
				<div className="user_name">{userData?.displayName}</div>
				<div className="user_img">
					<img src={userData?.photoURL} alt="" />
				</div>
				<div className="user_email">{userData?.email}</div>


				<div className="signout" onClick={logoff}>
					<img src={logout} alt="" />
					Sign Out
				</div>
			</div>
		</div>
	);
}

export default Menu;
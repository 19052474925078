// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyC8e7OotuzcCvnL6SdCGEuOW7e7tZ3TIZ8",
	authDomain: "vahaan.online",
	projectId: "smart-vahaan",
	storageBucket: "smart-vahaan.appspot.com",
	messagingSenderId: "360258628898",
	appId: "1:360258628898:web:dabdb914f3d6e4ef6a097b",
	measurementId: "G-Z2GELGSEHZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line
const analytics = getAnalytics(app);

export const auth = getAuth();
export const provider = new GoogleAuthProvider();
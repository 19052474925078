import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
	name: "user",
	initialState: ["swa"],
	reducers: {
		logedIn: (state, action) => {
			return action.payload;
		},
		logedOut: (state, action) => {
			return null;
		},
	},
});

export const { logedIn, logedOut } = userSlice.actions;

export default userSlice.reducer;
import React, { useEffect } from 'react'
import "./style.css"
// import { formValidation } from '../../action/login';
import { GoogleLogin } from '../../action/auth';
// import { logedIn } from '../../store/slice/userSlice';
// import { useDispatch } from 'react-redux';
import Gbtn from "../../img/google.png"

const Login = (props) => {
	const { setShowLoader } = props;
	useEffect(() => {
		setShowLoader(false);
	}, []);
	const handleLogin = async () => {
		props.setShowLoader(true);
		await GoogleLogin();

	}

	return (
		<div className="body_vahan radi">
			<div className='login'>
				<div className="login_form">
					<img src="https://www.smartapps.in/assets/images/logo.svg" className='login_logo' alt="" />

					{/* <form action="" onSubmit={(e) => e.preventDefault()}>
						<div className="formBox">
							{!login && <input ref={name} type="text" placeholder='Enter Your Name' />}
							<input ref={email} type="text" placeholder='Enter Email ID' />
							<input ref={pass} type="password" placeholder='Enter Password' />
							<button onClick={handleLogin} className='btn-grad'>{login ? "Login" : "SIGN UP"}</button>
							{errMsg && <div className="err">{errMsg}</div>}
						</div>
					</form>
					<div className='switch' onClick={handleCreateAccount} >{login ? "New in Vahan? Create an Account." : "Existing User? Login Now"}</div> */}
					<h2>Welcome !</h2>
					<div className='check'>Check Car Owner's Details </div>
					<div className="social_login">
						<div className="btn" onClick={handleLogin} >
							<img src={Gbtn} alt="" />
							Login with Google
						</div>
					</div>
				</div>

			</div>
		</div>
	)
}

export default Login;
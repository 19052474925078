import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/userSlice";
import carOwnReducer from "./slice/carOwn";
import imgReducer from "./slice/imgData"

const appStore = configureStore({
	reducer: {
		user: userReducer,
		carOwner: carOwnReducer,
		image: imgReducer
	},
});

export default appStore;
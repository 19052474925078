import "./style.css"
const Logo = () => {
	return (
		<div className="carBox">
			<div className="country-wrap">
				<div className="car">
					<div className="car-body"></div>
					<div className="car-top-back">
						<div className="car-top-back--window"></div>
					</div>
					<div className="car-top-front">
						<div className="car-top-front--window"></div>
					</div>
					<div className="car-bonnot-front"></div>
					<div className="car-tyre-cover">
						<div className="car-tyre-cover--front"></div>
						<div className="car-tyre-cover--back">
							<div className="car-tyre-cover--back--bottom"></div>
						</div>
					</div>
					<div className="car-gate"></div>
					<div className="car-stepney"></div>
					<div className="car-tyre car-tyre-front">
						<div className="car-tyre--gap"></div>
					</div>
					<div className="car-tyre car-tyre-back">
						<div className="car-tyre--gap"></div>
					</div>
					<div className="car-inner-boundry car-inner-boundry--back"></div>
					<div className="car-inner-boundry car-inner-boundry--front"></div>
					<div className="car-shadow"></div>
					<div className="car-base-axcel"></div>
					<div className="car-bumper-front"></div>
				</div>
			</div>
		</div>
	);
}

export default Logo;
import { useEffect, useState } from "react";
import Search from "./Search";
import "./style.css"
import { useDispatch, useSelector } from "react-redux";
import { Image } from "../../action/image";
import { carImage } from "../../store/slice/imgData";
import Details from "./Details";
import newsearch from "../../img/newsearch.png"
// import { ownerInfo } from "../../store/slice/carOwn";



const Vahaan = (props) => {
	const dispatch = useDispatch();
	const img = useSelector((store) => store.image);
	const [imgPath, setImgPath] = useState(null);
	const [newSearch, setNewSearch] = useState(false);
	const ownerData = useSelector((store) => store.carOwner)

	useEffect(() => {
		props.setShowLoader(false);
	});


	const imageAPI = async (val) => {
		const imgSrcData = await Image(val);
		dispatch(carImage({ imgSrcData }))
	}

	useEffect(() => {
		// console.warn(ownerData)
		if (ownerData?.vehicle_make_model || ownerData?.kgi_variant_desc) {
			const veh = ownerData?.kgi_make_desc + " " + ownerData?.kgi_model_desc ||
				ownerData?.kgi_make_desc + ownerData?.kgi_variant_desc
			// console.warn(veh)
			imageAPI(veh);
			setNewSearch(true);
		}
	}, [ownerData]);

	useEffect(() => {
		if (img?.imgSrcData?.photos[0]?.src?.medium != null) {
			setImgPath(img?.imgSrcData?.photos[0]?.src?.medium);
			setNewSearch(true);
		}
	}, [img]);


	// console.warn(ownerData);
	const newCarSearch = () => {
		setNewSearch(false);
	}
	useEffect(() => {
		if (imgPath != null) { setNewSearch(true); }
	}, [imgPath]);



	// useEffect(() => () => {
	// 	dispatch(clearImage());
	// 	dispatch(ownerInfo(null))
	// }, []);

	return (
		<div className="body_vahan">
			{newSearch &&
				<div className="newSearch" onClick={newCarSearch}>
					<img src={newsearch} alt="" />
				</div>
			}
			{!newSearch && <Search {...props} />}
			{newSearch && <Details setNewSearch={setNewSearch} {...props} />}
		</div>
	);
}
export default Vahaan;